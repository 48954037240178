import { Link } from "gatsby"
import React from "react"
import { Button } from "react-bootstrap"
import OfficeForm from "../../../components/africa/officeform"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const OfficeSpacePage = () => (
  <Layout>
    <SEO title="Office Space" description="" lang="en" meta={[]} />

    <section className="office-space-page">
      <div className="transparent-window mt-5">
        <h1 className="text-center mb-5">Ready to work?</h1>
        <h2 className="text-center mb-3">Find your office space.</h2>

        <div>
          <OfficeForm />
        </div>
      </div>
    </section>
  </Layout>
)

export default OfficeSpacePage
